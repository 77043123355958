<template>


<div class="alert-notify-pop" @click="closeSysPop()">
        <div class="alert-notify" :class="{ zoomPop: isZoom }" @click.stop>
            <div class="alert-notify-title">
                <span>系统公告</span>
                <div @click="zoomPopFun()">
                    <i class="iconfont fullscreen" :class="!isZoom ? 'iconquanping6' : 'icontuichuquanping1'"></i>
                </div>
                <div>
                    <img @click="closeSysPop()"
                        src='https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8acf98a07bb16692fe62692859d76e2a.png' />
                </div>
            </div>
            <div class="alert-notify-content" v-loading="loading">
                <div class="alert-notify-content-list" v-loading="listLoading">
                    <div class="notify-top" v-if="!loading">
                        <div class="notify-top-tap">
                            <div class="notice" :class="{ noticeActive: switchList === 1 }" @click="toSwitchList(1)">
                                通知公告
                            </div>
                            <svg v-if="alertList" style="position: absolute;left: 100px;top: 10px;"
                                xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                <circle cx="3" cy="3" r="2.5" fill="#FC5558" />
                                <circle cx="3" cy="3" r="2.5" fill="url(#paint0_linear_1404_13871)"
                                    fill-opacity="0.2" />
                                <circle cx="3" cy="3" r="2.5" stroke="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_1404_13871" x1="3" y1="1" x2="3" y2="5"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" />
                                        <stop offset="1" stop-color="white" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <div class="notice" :class="{ noticeActive: switchList === 2 }" @click="toSwitchList(2)">
                                系统公告
                            </div>
                            <svg v-if="sysList" style="position: absolute;left: 242px;top: 10px;"
                                xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                <circle cx="3" cy="3" r="2.5" fill="#FC5558" />
                                <circle cx="3" cy="3" r="2.5" fill="url(#paint0_linear_1404_13871)"
                                    fill-opacity="0.2" />
                                <circle cx="3" cy="3" r="2.5" stroke="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_1404_13871" x1="3" y1="1" x2="3" y2="5"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" />
                                        <stop offset="1" stop-color="white" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <el-input placeholder="搜索" v-model="inputCtx" @input="searchItem"
                            style="text-align: center;outline: 1px solid transparent;">

                        </el-input>
                        <svg v-if="inputCtx === ''" style="position: absolute;top: 82px;left: 121px;"
                            xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M14.7347 13.5277L11.7417 10.5346C13.6266 8.27166 13.3982 4.92638 11.2232 2.94059C9.04825 0.954801 5.69605 1.03098 3.61351 3.11351C1.53098 5.19605 1.4548 8.54825 3.44059 10.7232C5.42638 12.8982 8.77166 13.1266 11.0346 11.2417L14.0277 14.2347C14.2229 14.43 14.5395 14.43 14.7347 14.2347C14.93 14.0395 14.93 13.7229 14.7347 13.5277ZM3.0177 7.0177C3.0177 4.53242 5.03242 2.5177 7.5177 2.5177C10.003 2.5177 12.0177 4.53242 12.0177 7.0177C12.0177 9.50298 10.003 11.5177 7.5177 11.5177C5.03356 11.5149 3.02046 9.50184 3.0177 7.0177Z"
                                fill="#8991A2" />
                        </svg>
                    </div>
                    <div v-if="(switchList === 1 && switchList1.length === 0 && !loading) || (switchList === 2 && switchList2.length === 0 && !loading)"
                        style="text-align: center;width: 100%;margin-top: 50px">暂无数据</div>
                    <div class="notify-list" v-else :class="{ listZoom: isZoom }" ref="notifyList">
                        <ul v-for="(item, index) in (switchList === 1 ? switchList1.slice((page - 1) * 20, (page === -1 ? switchList1.length : page * 20)) : switchList2.slice((page - 1) * 20, (page === -1 ? switchList2.length : page * 20)))"
                            :key="index">
                            <li class="list-item" :class="{ li: keyIndex === item.id }"
                                @click="switchKeyIndex(item, item.id)" @mouseenter="getMes(index, item.id)"
                                @mouseleave="inOut">
                                <svg v-if="+item.is_read === 0" style="position: absolute;left: 45px;top:17px"
                                    xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                    fill="none">
                                    <circle cx="4" cy="4" r="3.5" fill="#FC5558" />
                                    <circle cx="4" cy="4" r="3.5" fill="url(#paint0_linear_1404_14328)"
                                        fill-opacity="0.2" />
                                    <circle cx="4" cy="4" r="3.5" stroke="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_1404_14328" x1="4" y1="1" x2="4" y2="7"
                                            gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white" />
                                            <stop offset="1" stop-color="white" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div v-if="item.announcement_source === 1" class="item-type" v-color="item.notice_type">
                                    {{ item.notice_type }}</div>
                                <div v-else class="item-type" v-color="'通知'">系统</div>
                                <div class="item-content">
                                    <div class="content-title">
                                        <div>
                                            <marquee v-if="idCurrent === item.id">【{{item.notice_type}}】{{ item.title }}</marquee>
                                            <div v-else>【{{item.notice_type}}】{{ item.title }}</div>
                                        </div>
                                        <svg class="to-top-bnt" v-if="item.is_to_top" xmlns="http://www.w3.org/2000/svg"
                                            width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M1.2688 12.1152L3.74367 7.78414L1.47342 5.51389C1.30257 5.34304 1.30257 5.06603 1.47342 4.89517C1.56263 4.80597 1.68601 4.75961 1.81189 4.768L5.29047 4.99991L7.14662 3.14375L7.35546 1.26422C7.38214 1.02408 7.59845 0.851031 7.8386 0.877714C7.93734 0.888685 8.0294 0.932933 8.09964 1.00318L12.9995 5.90304C13.1704 6.0739 13.1704 6.35091 12.9995 6.52176C12.9293 6.59201 12.8372 6.63626 12.7385 6.64723L10.8589 6.85606L9.00278 8.71222L9.23468 12.1908C9.25076 12.4319 9.06835 12.6404 8.82726 12.6564C8.70138 12.6648 8.578 12.6185 8.48879 12.5293L6.21855 10.259L1.88752 12.7339L1.2688 12.1152Z"
                                                fill="#BFC7D8" />
                                        </svg>
                                    </div>
                                    <div class="content-main">
                                        <div class="main-text">{{ getFont(item.text) }}</div>
                                        <div class="main-time">
                                            {{ parseTime(item.created_at, '{m}-{d} {h}:{i}') }}

                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="alert-notify-list-bottom" v-if="!loading && switchList === 1">
                        <pagination layout="prev, pager, next" :total="switchList1.length" small :current-page="page"
                            v-if="switchList1.length" @current-change="changePage" :page-size="20">
                        </pagination>
                        <div class="bottom-box" @click="allRead">
                            <div style="position: relative;">
                                <svg v-if="alertList" style="position: absolute;left: 10px"
                                    xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6"
                                    fill="none">
                                    <circle cx="3" cy="3" r="2.5" fill="#FC5558" />
                                    <circle cx="3" cy="3" r="2.5" fill="url(#paint0_linear_1404_13871)"
                                        fill-opacity="0.2" />
                                    <circle cx="3" cy="3" r="2.5" stroke="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_1404_13871" x1="3" y1="1" x2="3" y2="5"
                                            gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white" />
                                            <stop offset="1" stop-color="white" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <i class="iconfont iconquanbuyidu"></i>
                            </div>
                            <span>全部已读</span>
                        </div>
                    </div>
                    <div class="alert-notify-list-bottom" v-if="!loading && switchList === 2">
                        <pagination layout="prev, pager, next" :total="switchList2.length" small :current-page="page"
                            v-if="switchList2.length" @current-change="changePage" :page-size="20">
                        </pagination>
                        <div class="bottom-box" @click="allRead">
                            <div style="position: relative;">
                                <svg v-if="sysList" style="position: absolute;left: 10px"
                                    xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6"
                                    fill="none">
                                    <circle cx="3" cy="3" r="2.5" fill="#FC5558" />
                                    <circle cx="3" cy="3" r="2.5" fill="url(#paint0_linear_1404_13871)"
                                        fill-opacity="0.2" />
                                    <circle cx="3" cy="3" r="2.5" stroke="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_1404_13871" x1="3" y1="1" x2="3" y2="5"
                                            gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white" />
                                            <stop offset="1" stop-color="white" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <i class="iconfont iconquanbuyidu"></i>
                            </div>
                            <span>全部已读</span>
                        </div>
                    </div>
                </div>
                <div class="alert-notify-content-main" v-if="!loading">
                    <popContentMain :class="{ popContentMain: setDeal }" :itemType=2 :itemData="itemData">
                    </popContentMain>
                    <popContentSider class="popContentSider" :itemType=2 :switchType=2 :itemData="itemData"
                        @toSysItemDataIsUpdate="toSysItemDataIsUpdate" :isZoom="isZoom" @on-handle="tochange"
                        :siderPlush="siderPlush" :isReply="isReply">
                    </popContentSider>
                    <div :class="{ activeDispose: setDeal }" class="alert-dispose" v-if="setDeal">
                        <div class="dispose-title">
                            <span>回复公文</span>
                            <div @click="cancelEdit()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M16.25 10.4365L11.5089 15.1776L10.625 14.2938L14.9242 9.99458L10.625 5.69541L11.5089 4.81152L16.25 9.55264C16.4941 9.79672 16.4941 10.1924 16.25 10.4365ZM9.37505 10.4365L4.63393 15.1776L3.75005 14.2938L8.04922 9.99458L3.75005 5.69541L4.63393 4.81152L9.37505 9.55264C9.61912 9.79672 9.61912 10.1924 9.37505 10.4365Z"
                                        fill="#8991A2" />
                                </svg>
                            </div>
                        </div>
                        <div class="dispose-main" :class="{ zoomDis: isZoom }">
                            <el-form label-position="top" :model="formLabelAlign" :rules="rules" ref="ruleForm">
                                <el-form-item label="回复对象" prop="repUser">
                                    <UserList class="user-list" @on-select-change="onSelectUser" :isItself="true">
                                    </UserList>
                                </el-form-item>
                                <el-form-item label="内容" prop="textarea">
                                    <el-input placeholder="请输入" type="textarea" style="width: 340px;"
                                        v-model="formLabelAlign.textarea"></el-input>
                                </el-form-item>

                                <el-form-item label="图片">
                                    <fileUpload drag :fileTypeList="typeFile" style="width: 340px;"
                                        @extra-files="extraFiles1" :files="picList"></fileUpload>
                                </el-form-item>
                                <el-form-item label="附件">
                                    <fileUpload :files="attachList" :onlyoffice="onlyoffice" drag
                                        style="width: 340px;height: 96px;" @extra-files="extraFiles2">
                                    </fileUpload>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="dispose-bottom">
                            <button @click="cancelEdit()" class="bnt-cancel">取消</button>
                            <button @click="submitRey(ruleForm)" class="bnt-confirm">确定</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { defineEmits, ref, inject, onMounted, computed, nextTick } from 'vue';
import popContentMain from '../../popContentMain.vue';
import { Pagination } from 'element-ui';
import popContentSider from '../../popContentSider.vue';
import fileUpload from '../../../../../components/fileUpload/newFiles/index.vue'
import UserList from "../../../../../custom-component/form/formParser/selectUser/index.vue"
import { dataInterface } from '@/apis/data';
import { Message } from 'element-ui';
import marquee from '../marquee.vue';
import { parseTime } from '@/utils/util';

const sysItemData = ref([]);
const noReadList = ref([]);
const searchList = ref([]);
const setDeal = ref(false);
const switchList1 = ref([]);
const switchList2 = ref([]);
const onlyoffice = ref(false);
const siderPlush = ref(false);
const formLabelAlign = ref({
    textarea: "",
    repUser: [],
    picList: [],
    attachList: []
});
const ruleForm = ref();
const picList = ref([]);
const attachList = ref([]);
const d = ref(-1);
const idCurrent = ref(-1);
const listLoading = ref(false);
const alertList = ref(0);
const sysList = ref(0);
const switchList = ref(1);
const keyIndex = ref(-1);
const itemData = ref({});
const count = ref(0);
const inputCtx = ref("");
const loading = ref(true);
const isZoom = ref(false);
const notifyList = ref();
const scrollTopValue = ref(0);
const page = ref(1);
const isReply = ref(false);

const changePage = (p) => {
    page.value = p;
}

const cancelEdit = () => {
    setDeal.value = false;
}

const typeFile = ref(["png", "jpg", "jpeg", "gif"]);

const tochange = (payload) => {
    if (payload) {
        setDeal.value = false;
    } else {
        setDeal.value = true;
        formLabelAlign.value.textarea = ""
        picList.value = []
        attachList.value = []
    }

}

const rules = {
    textarea: [
        { required: true, message: '请输入内容', trigger: 'blur' }
    ],
    repUser: [
        { required: true, message: '请选择人员', trigger: 'blur' }
    ]
}

const onSelectUser = (arr) => {
    formLabelAlign.value.repUser = []
    arr.forEach(item => {
        formLabelAlign.value.repUser.push({
            id: item.id,
            name: item.name,
            user_table: item.user_table,
            user_id: item.user_id,
            section_id: item.section_id
        })
    })
}

const extraFiles1 = (p) => {
    formLabelAlign.value.picList = []
    p.forEach(item => {
        formLabelAlign.value.picList.push({
            file_md5: item.file_md5,
            filesize: item.filesize,
            filetype: item.filetype,
            fileext: item.fileext,
            filename: item.filename,
            filepath: item.filepath,
            thumb_path: item.thumb_path,
            user_id: item.user_id,
            user_name: item.user_name,
            role_id: item.role_id,
            role_name: item.role_name,
            archi_id: item.archi_id,
            ip: item.ip,
            updated_at: item.updated_at,
            created_at: item.created_at,
            id: item.id,
            url: item.url,
            upTime: item.upTime,
            name: item.name,
            path: item.path
        })
    })
    picList.value = p;
}

const extraFiles2 = (p) => {
    formLabelAlign.value.attachList = []
    p.forEach(item => {
        formLabelAlign.value.attachList.push({
            file_md5: item.file_md5,
            filesize: item.filesize,
            filetype: item.filetype,
            fileext: item.fileext,
            filename: item.filename,
            filepath: item.filepath,
            thumb_path: item.thumb_path,
            user_id: item.user_id,
            user_name: item.user_name,
            role_id: item.role_id,
            role_name: item.role_name,
            archi_id: item.archi_id,
            ip: item.ip,
            updated_at: item.updated_at,
            created_at: item.created_at,
            id: item.id,
            url: item.url,
            upTime: item.upTime,
            name: item.name,
            path: item.path
        })
    })
    attachList.value = p;
}

const submitRey = (formEl) => {
    formEl.validate((validate) => {
        if (validate) {
            dataInterface({
                __method_name__: "updateData",
                object_uuid: "object6502ac1128ecd",
                replay_object: formLabelAlign.value.repUser,
                main_text: formLabelAlign.value.textarea,
                pic: formLabelAlign.value.picList,
                attachment: formLabelAlign.value.attachList,
                data_id: itemData.value.announcements_son_id
            }).then(res => {
                if (res.data.code === 200) {
                    setDeal.value = false;
                    isReply.value = !isReply.value;
                    toSysItemDataIsUpdate();
                }
            })
        }
    })

}

const getMes = (index, id) => {
    d.value = index
    idCurrent.value = id
}
const inOut = () => {
    d.value = -1
    idCurrent.value = -1
}

const getFont = computed(() => {
    return function (str) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(str, 'text/html');
        return doc.body.textContent || "-";
    }
});

onMounted(() => {
    let list1 = []
    let list2 = []
    let list3 = []
    let list4 = []
    let list5 = []
    let list6 = []
    let arry = []
    page.value = 1
    dataInterface({}, 'api/graph/1287').then(res => {
        if (res.data.code === 200) {
            res.data.data.forEach(item => {
                if (item.is_to_top) {
                    list1.push(item)
                } else {
                    list2.push(item)
                }
            })
            list1.forEach(item => {
                if (+item.is_read !== 1) {
                    list3.push(item)
                } else {
                    list4.push(item)
                }
            })
            list2.forEach(item => {
                if (+item.is_read !== 1) {
                    list5.push(item)
                } else {
                    list6.push(item)
                }
            })
            list5.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
            list6.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
            list3.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
            list4.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
            arry = [...list3, ...list4, ...list5, ...list6]
            sysItemData.value = arry
            searchList.value = arry
            loading.value = false
            let num = 0;
            sysItemData.value.forEach((item, index) => {
                if (item.announcement_source === 1 && num === 0) {
                    itemData.value = item
                    keyIndex.value = item.id
                    num = 1
                }
                if (+item.is_read === 0) {
                    noReadList.value.push(item)
                }
                if (item.announcement_source === 1) {
                    switchList1.value.push(item)
                } else {
                    switchList2.value.push(item)
                }
            })
            noReadList.value.forEach(item => {
                if (item.announcement_source === 1) {
                    if (+item.is_read === 0) {
                        alertList.value++
                    }
                } else {
                    if (+item.is_read === 0) {
                        sysList.value++
                    }
                }
            })
            nextTick(() => {
                scrollTopValue.value = notifyList.value?.scrollHeight
            })

        }
    })
})

const emits = defineEmits(["close-pop"])
const closeSysPop = () => {
    emits("close-pop");
};
const props = defineProps({
    SysItemData: {
        type: [],
        default: () => []
    }
})


const toSwitchList = (num) => {
    switchList.value = num;
    page.value = 1;
}

const switchKeyIndex = (payload1, payload2) => {
    keyIndex.value = payload2;
    itemData.value = payload1;
}

const searchItem = () => {
    count.value = 0;
    let list1 = [];
    let list2 = [];
    let list3 = [];
    let list4 = [];
    let list5 = [];
    let list6 = [];
    let arry = [];
    if (inputCtx.value === "") {
        page.value = 1;
    } else {
        page.value = -1;
    }
    searchList.value.forEach(item => {
        if (item.title.includes(inputCtx.value)) {
            if (item.is_to_top) {
                list1.push(item);
            } else {
                list2.push(item);
            }
            count.value++;
        }
    })
    list1.forEach(item => {
        if (+item.is_read !== 1) {
            list3.push(item);
        } else {
            list4.push(item);
        }
    })
    list2.forEach(item => {
        if (+item.is_read !== 1) {
            list5.push(item);
        } else {
            list6.push(item);
        }
    })
    list5.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
    list6.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
    list3.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
    list4.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
    arry = [...list3, ...list4, ...list5, ...list6];
    list1 = [];
    list2 = [];
    arry.forEach(item => {
        if (item.announcement_source === 1) {
            list1.push(item);
        } else {
            list2.push(item);
        }
    })
    switchList1.value = list1;
    switchList2.value = list2;
    sysItemData.value = arry;
}

const toUpdate = inject(['toUpdate']);
const allRead = () => {
    const userId = JSON.parse(localStorage.getItem("userInfo")).id;
    const section_id = JSON.parse(localStorage.getItem("targetArchi")).sectionId;
    if (switchList.value === 1 && alertList.value) {
        dataInterface({
            __method_name__: "customEventCall",
            object_uuid: "844a47c6-6380-46dd-bc4a-d73b6245175f",
            view_uuid: "view66a9ebcc8fcaf",
            data_id: userId, //值取当前登录用户id
            event: "设置通知公告全部已读"
        }).then(res => {
            if (res.data.code === 200) {
                toSysItemDataIsUpdate(true);
                alertList.value = 0;
            }
        })
    } else if (switchList.value !== 1 && sysList.value) {
        dataInterface(
            {
                __method_name__: "customEventCall", //该参数值取固定值
                object_uuid: "844a47c6-6380-46dd-bc4a-d73b6245175f", //该参数值取固定值
                view_uuid: "view66a9ebcc8fcaf", //该参数值取固定值
                data_id: userId, //值取当前登录用户id
                section_id: section_id, //取当前登录用户所在部门id
                event: "系统公告设置全部已读" //该参数值取固定值
            }
        ).then(res => {
            if (res.data.code === 200) {
                toSysItemDataIsUpdate(true);
                sysList.value = 0;

            }
        })
    } else {
        Message({
            message: '已无未读数据',
            type: 'message'
        })
    }

}

const toSysItemDataIsUpdate = (payload) => {
    listLoading.value = true;
    let list = [];
    let count1 = 0;
    let list1 = [];
    let list2 = [];
    let list3 = [];
    let list4 = [];
    let list5 = [];
    let list6 = [];
    let count2 = 0;
    let itemList = [];
    dataInterface({}, 'api/graph/1287').then(res => {
        if (res.data.code === 200) {
            res.data.data.forEach(item => {
                if (+item.is_read === 0) {
                    list.push(item)
                }
                if (item.is_to_top) {
                    list1.push(item)
                } else {
                    list2.push(item)
                }
            })
            list.forEach(item => {
                if (item.announcement_source === 1) {
                    count1++
                } else {
                    count2++
                }
            })
            list1.forEach(item => {
                if (+item.is_read !== 1) {
                    list3.push(item)
                } else {
                    list4.push(item)
                }
            })
            list2.forEach(item => {
                if (+item.is_read !== 1) {
                    list5.push(item)
                } else {
                    list6.push(item)
                }
            })
            list5.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
            list6.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
            list3.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
            list4.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
            itemList = [...list3, ...list4, ...list5, ...list6];
            list1 = [];
            list2 = [];
            itemList.forEach((item) => {
                if (item.announcement_source === 1) {
                    list1.push(item)
                } else {
                    list2.push(item)
                }
            })
            if (switchList.value === 1) {
                switchList1.value.slice((page.value - 1) * 20, page.value * 20).forEach((item, index) => {
                    if (item.id === keyIndex.value) {
                        nextTick(() => {
                            notifyList.value.scrollTop = scrollTopValue.value / switchList1.value.slice((page.value - 1) * 20, page.value * 20).length * index
                        })

                    }
                })
            } else {
                switchList2.value.slice((page.value - 1) * 20, page.value * 20).forEach((item, index) => {
                    if (item.id === keyIndex.value) {
                        nextTick(() => {
                            notifyList.value.scrollTop = scrollTopValue.value / switchList2.value.slice((page.value - 1) * 20, page.value * 20).length * index
                        })

                    }
                })
            }
            switchList1.value = list1;
            switchList2.value = list2;
            noReadList.value = list;
            alertList.value = count1;
            sysList.value = count2;
            sysItemData.value = itemList;
            searchList.value = itemList;
            listLoading.value = false;
            toUpdate(false, true);
            if (payload) {
                siderPlush.value = !siderPlush.value;
            }
            Message({
                message: '操作成功',
                type: 'success'
            })
        }
    })

}

const zoomPopFun = () => {
    isZoom.value = !isZoom.value;
}

const vColor = (el, binding) => {

    if (binding.value === "公告" || binding.value === "公报") {
        el.style.background = "linear-gradient(180deg, #FFD747 0%, #FCA832 100%)"
    } else if (binding.value === "意见") {
        el.style.background = "linear-gradient(180deg, #62F5C4 0%, #4BD1C4 100%)"
    } else if (binding.value === "命令") {
        el.style.background = "#0FB7FA"
    } else if (binding.value === "决定" || binding.value === "决议") {
        el.style.background = "linear-gradient(180deg, #46C7FA 0%, #0F94FA 100%)"
    } else {
        el.style.background = "linear-gradient(180deg, #6FDCFC 0%, #55BEF0 100%)"
    }

}
</script>

<style lang="less" scoped>
.alert-notify-pop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 10;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .zoomPop {
        display: flex;
        width: 100% !important;
        height: 100% !important;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        border-radius: var(--Radio-16, 16px);
        background: var(--overall-surface-default, #FFF);
        overflow: hidden;
    }

    .alert-notify {
        display: flex;
        width: 1280px;
        height: 800px;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        border-radius: var(--Radio-16, 16px);
        background: var(--overall-surface-default, #FFF);
        overflow: hidden;

        .alert-notify-title {
            display: flex;
            height: 56px;
            padding: 0px var(--spacing-10, 20px) 0px var(--spacing-12, 24px);
            align-items: center;
            gap: 12px;
            flex-shrink: 0;
            align-self: stretch;
            border-bottom: 1px solid var(--border-on-surface-weak, #F0F1F4);

            span {
                color: var(--text-on-surface-primary, #181B22);
                font-family: "MiSans VF";
                font-size: 16px;
                font-style: normal;
                font-weight: 380;
                line-height: 24px;
                /* 150% */
                flex: 1 0 0;
            }

            div {
                border-radius: 5px;

                img {
                    display: flex;
                    align-items: flex-start;
                    border-radius: var(--radius-2, 4px);
                    width: 32px;
                }
            }

            .fullscreen {
                font-size: 22px;
                color: #4D535E;
            }

            // div:hover {
            //     background-color: #F4F6F9;
            // }

            // div:active {
            //     background-color: #b8becce3;
            // }
        }

        .alert-notify-content {
            display: flex;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
            height: calc(100% - 57px);

            .alert-notify-content-list {
                display: flex;
                width: 320px;
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
                align-self: stretch;
                border-radius: var(--Radio-16, 16px) 0px 0px 0px;
                border-right: 1px solid var(--border-on-surface-weak, #F0F1F4);
                background: var(--overall-surface-default, #FFF);
                position: relative;

                .notify-top {
                    display: flex;
                    padding: 16px 16px 0px 16px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;

                    :deep(.el-input__inner) {
                        text-align: center;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        color: var(--text-on-surface-placeholder, #A4ACBD);
                        text-overflow: ellipsis;

                        /* web/cn/body/body-01 */
                        font-family: "PingFang SC";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        /* 157.143% */
                        letter-spacing: 0.5px;
                    }

                    .notify-top-tap {
                        display: flex;
                        height: 36px;
                        padding: 3px;
                        align-items: center;
                        gap: 3px;
                        align-self: stretch;
                        border-radius: var(--Radio-8, 8px);
                        background: var(--overall-surface-variant, #F2F5FA);
                        position: relative;

                        .notice {
                            display: flex;
                            padding: 4px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;
                            flex: 1 0 0;
                            align-self: stretch;
                            border-radius: var(--Radio-6, 6px);
                            color: #707786;
                        }

                        .noticeActive {
                            display: flex;
                            padding: 4px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;
                            flex: 1 0 0;
                            align-self: stretch;
                            border-radius: var(--Radio-6, 6px);
                            background: var(--overall-surface-default, #FFF);
                            color: #387FFC;
                            /* shadow/on-surface/medium */
                            box-shadow: 0px 1px 3px 0px rgba(25, 27, 35, 0.04), 0px 0px 0px 0px rgba(255, 255, 255, 0.01) inset, 0px 2px 16px 0px rgba(25, 27, 35, 0.08);
                        }
                    }

                    .notify-top-search {
                        display: flex;
                        height: var(--button-list-field-medium, 32px);
                        padding: 0px var(--spacing-none, 0px) 0px 0px;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        align-self: stretch;
                        border-radius: 8px;
                        border: 1px solid var(--field-border-default, #F0F1F4);
                        background: var(--overall-surface-default, #FFF);

                        img {
                            width: var(--Radio-16, 16px);
                            height: var(--Radio-16, 16px);
                        }

                        span {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                            color: var(--text-on-surface-placeholder, #A4ACBD);
                            text-overflow: ellipsis;

                            /* web/cn/body/body-01 */
                            font-family: "PingFang SC";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px;
                            /* 157.143% */
                            letter-spacing: 0.5px;
                        }
                    }
                }

                .listZoom {
                    height: 700px !important;
                    overflow-x: hidden;
                    width: inherit;
                }

                .notify-list {
                    width: inherit;
                    height: 550px;
                    overflow-x: hidden;

                    ul {
                        padding: 0;
                        margin: 0;
                        display: flex;
                        padding: var(--spacing-none, 0px) 16px;
                        flex-direction: column;
                        align-items: flex-start;
                        flex: 1 0 0;
                        align-self: stretch;

                        .li {
                            background: var(--overall-surface-variant, #F2F5FA);
                        }

                        .list-item:hover {
                            background: var(--overall-surface-variant, #F2F5FA);
                        }

                        .list-item {
                            display: flex;
                            height: 64px;
                            padding: 0px 16px;
                            align-items: center;
                            gap: 12px;
                            align-self: stretch;
                            border-radius: var(--Radio-8, 8px);
                            position: relative;
                            // background: var(--overall-surface-variant, #F2F5FA);

                            .item-type {
                                display: flex;
                                width: 36px;
                                height: 36px;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                border-radius: var(--Radio-999, 999px);
                                // background: linear-gradient(180deg, #6FDCFC 0%, #55BEF0 100%);
                                color: rgba(255, 255, 255, 0.90);
                                text-align: right;
                                text-shadow: 0px 0.45px 0.9px rgba(0, 0, 0, 0.10);
                                font-family: "MiSans VF";
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 450;
                                line-height: 20px;
                                /* 153.846% */
                                /* 153.846% */
                            }

                            .item-content {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                                flex: 1 0 0;
                                align-self: stretch;

                                .content-title {
                                    display: flex;
                                    align-items: center;
                                    gap: 6px;
                                    align-self: stretch;

                                    .to-top-bnt {
                                        padding: 3px;
                                        border-radius: 3px;
                                    }

                                    img {
                                        display: flex;
                                        width: 22px;
                                        height: 22px;
                                        justify-content: center;
                                        align-items: center;
                                        gap: 8px;
                                    }

                                    div {
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 1;
                                        flex: 1 0 0;
                                        overflow: hidden;
                                        color: var(--text-on-surface-primary, #181B22);
                                        text-overflow: ellipsis;
                                        font-family: "MiSans VF";
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 330;
                                        line-height: 22px;
                                        width: 178px;
                                        /* 157.143% */
                                    }
                                }

                                .content-main {
                                    display: flex;
                                    align-items: center;
                                    gap: var(--spacing-12, 24px);
                                    align-self: stretch;

                                    .main-text {
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 1;
                                        flex: 1 0 0;
                                        overflow: hidden;
                                        color: var(--text-on-surface-tertiary, #707786);
                                        text-overflow: ellipsis;
                                        font-family: "MiSans VF";
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 330;
                                        line-height: 18px;
                                        width: 115px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        // white-space: nowrap;
                                        /* 150% */
                                    }

                                    .main-time {
                                        color: var(--text-on-surface-placeholder, #A4ACBD);
                                        text-align: right;
                                        font-family: "MiSans VF";
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 330;
                                        line-height: 18px;
                                        /* 150% */
                                    }
                                }
                            }
                        }
                    }

                }

                .alert-notify-list-bottom {
                    position: absolute;
                    bottom: 0px;
                    display: flex;
                    height: 48px;
                    padding: 0px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                    width: -webkit-fill-available;

                    ::v-deep(.el-pagination) {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 48px;
                    }

                    .bottom-box:hover {
                        & span {
                            color: #387FFC;
                        }

                        & i {
                            color: #387FFC;
                        }
                    }

                    .bottom-box {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        flex: 1 0 0;
                        align-self: stretch;
                        // width: 100%;
                        border-top: 1px solid var(--border-on-surface-weak, #F0F1F4);

                        i {
                            color: #A4ACBD;
                        }

                        img {
                            width: 20px;
                            height: 20px;
                        }

                        span {
                            color: var(--text-on-surface-placeholder, #A4ACBD);
                            font-family: "MiSans VF";
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 330;
                            line-height: 20px;
                            /* 153.846% */
                        }
                    }
                }
            }

            .alert-notify-content-main {
                display: flex;
                padding-right: 104px;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                align-self: stretch;
                box-sizing: border-box;
                position: relative;

                .popContentMain {
                    transform: translateX(-150px);

                    :deep(.detail-content) {
                        height: 100vh;
                    }
                }

                .activeDispose {
                    display: flex;
                    background-color: white;
                    width: 400px;
                    height: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                    position: absolute;
                    right: -0px !important;
                    top: -1px;
                    border-left: 1px solid var(--border-on-surface-weak, #F0F1F4);
                    border-top: 1px solid var(--border-on-surface-weak, #F0F1F4);
                    box-sizing: border-box;
                }

                .popContentSider {
                    display: flex;
                    width: 200px;
                    height: 744px;
                    padding: 48px var(--spacing-16, 32px) 48px 0px;
                    flex-direction: column;
                    align-items: center;
                    gap: var(--spacing-16, 32px);
                    position: absolute;
                    right: 0px;
                }

                .alert-dispose {
                    display: flex;
                    background-color: white;
                    width: 400px;
                    height: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                    position: absolute;
                    right: -400px;
                    top: -1px;
                    border-left: 1px solid var(--border-on-surface-weak, #F0F1F4);
                    border-top: 1px solid var(--border-on-surface-weak, #F0F1F4);
                    box-sizing: border-box;


                }

                .dispose-title {
                    display: flex;
                    height: 56px;
                    padding: 0px var(--spacing-10, 20px) 0px var(--spacing-12, 24px);
                    align-items: center;
                    gap: 8px;
                    flex-shrink: 0;
                    align-self: stretch;

                    span {
                        color: var(--text-on-surface-primary, #181B22);
                        font-family: "MiSans VF";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 380;
                        line-height: 24px;
                        /* 150% */
                        flex: 1 0 0;
                    }

                    img {
                        display: flex;
                        width: var(--button-list-field-medium, 32px);
                        height: var(--button-list-field-medium, 32px);
                        justify-content: center;
                        align-items: center;
                    }
                }

                .zoomDis {
                    display: flex;
                    padding: 0px var(--spacing-12, 24px);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    flex: 1 0 0;
                    align-self: stretch;
                    max-height: 770px !important;
                    overflow-x: hidden;
                }

                .dispose-main {
                    display: flex;
                    padding: 0px var(--spacing-12, 24px);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    flex: 1 0 0;
                    align-self: stretch;
                    max-height: 621px;
                    overflow-x: hidden;

                    .el-form {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 16px;
                        flex: 1 0 0;

                        :deep(.el-form-item__label) {
                            padding: 0px
                        }

                        :deep(.el-input__prefix) {
                            left: 0px;
                        }
                    }

                    .el-form-item {
                        margin: 0;

                        .user-list {
                            width: 340px;
                        }

                        ::v-deep {
                            .el-textarea__inner:hover {
                                border-color: rgb(31, 115, 241);
                            }
                        }

                        ::v-deep(.el-input__prefix) {
                            .show-value:hover {
                                border-color: rgb(31, 115, 241);
                            }

                            .show-value {
                                overflow: hidden !important;
                                text-overflow: ellipsis !important;
                                white-space: nowrap !important;
                            }
                        }

                        ::v-deep(.drag-img) {
                            .drag-img-item:hover {
                                border-color: rgb(31, 115, 241);
                            }
                        }
                    }

                    ::v-deep(.el-upload-dragger) {
                        height: 110px;
                    }

                    // ::v-deep(.drag-img-item){
                    //     width: 100px;
                    //     h100px
                    // }
                }

                .dispose-bottom {
                    display: flex;
                    padding: 16px var(--spacing-10, 20px);
                    justify-content: flex-end;
                    align-items: center;
                    gap: var(--spacing-6, 12px);
                    align-self: stretch;
                    background-color: white;
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    width: -webkit-fill-available;

                    .bnt-cancel {
                        display: flex;
                        height: 32px;
                        padding: 0px var(--spacing-16, 32px);
                        justify-content: center;
                        align-items: center;
                        gap: var(--spacing-3, 6px);
                        border-radius: var(--radius-3, 6px);
                        background: var(--_button-neutral-tertiary-background, #F0F1F4);
                    }

                    .bnt-cancel:hover {
                        background-color: #dfe6ec;
                    }

                    .bnt-cancel:active {
                        background-color: #b8becce3;
                    }

                    .bnt-confirm {
                        display: flex;
                        height: 32px;
                        padding: 0px var(--spacing-16, 32px);
                        justify-content: center;
                        align-items: center;
                        gap: var(--spacing-3, 6px);
                        border-radius: var(--radius-3, 6px);
                        background: var(--themeColor);
                        color: #FFFFFF;
                    }
                }

            }
        }
    }
}
</style>